import { useHistory } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import logo from '../logo.png'

export default function Menu() {
    const history = useHistory();

    function toHome(){
        history.push('/');
    }

    function toServices(){
        history.push('/services');
    }

    function toFaq(){
        history.push('/faq');
    }

    function toAbout(){
        history.push('/about');
    }
    function toContact(){
        history.push('/contact');
    }

    return(
        <Navbar expand="lg" className="p-4 border-bottom border-light">
            <Container>
                <Navbar.Brand href="/"><img src={logo} alt="Logo"/></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-5 ps-5 fw-bold">
                        <Nav.Link onClick={toHome} className="p-4">Home</Nav.Link>
                        <Nav.Link onClick={toServices} className="p-4">Services</Nav.Link>
                        <Nav.Link onClick={toFaq} className="p-4">FAQ</Nav.Link>
                        <Nav.Link onClick={toAbout} className="p-4">About Us</Nav.Link>
                        <Nav.Link onClick={toContact} className="p-4">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Calculator, AwardFill } from 'react-bootstrap-icons'

import ServiceList from './components/ServiceList'
import Subscribe from './components/Subscribe'


export default function Home() {
    const services1 = [
        "4-Wheel Alignment", "Battery Replacement", "General Repair and Maintenance",
        "Transmission Flush", "Mass Air Flow Service", "Brake Repair and Replacement",
        "A/C Service and Repair", "Tire Repair and Replacement"
    ]

    const services2 = [
        "Exhaust System Repair", "Fuel and Air Induction",  "Engine Cooling System Maintenance",
         "Electrical Diagnostics", "Starting and Charging Repair", "Power Steering Flush",
         "Coolant Flush",  "Computer Diagnostic Testing", ]

    const services3 = [
        "Preventative Maintenance", "New York State Inspection",  "Fleet Maintenance", 
        "Engine Performance Tune Up", "Oil Change", "Brake Fluid Moisture Content Test", 
        "Headlight Service", "Steering and Suspension"]

    

    return(
        <>
            <Container className="text-center p-4">
                <h2>Welcome to Ralph's Automotive Services, Inc.</h2>
                <p>We specialize in Full Auto Servicing and Auto Repair in Tivoli, NY.</p> 
            </Container>

            <Container fluid className="whatWeDo bg-light p-4 text-center">
                <Container lg>
                    <header>
                        <h1>What We Do</h1>
                        <p>We offer full service auto repair &amp; maintenance</p>
                    </header>

                    <Row className="service-blocks" lg={3} md={3} sm={2} xs={1}>
                        <Col className="bg-primary text-white p-4">
                            <h2 className="fw-bold">Preventative <br/> Maintenance</h2>
                            The best way to minimize breakdowns is doing routine maintenance
                        </Col>
                        <Col>
                            <img src="assets/img/service-1.jpg" alt="Oil" style={{width: 100 + '%'}}/>
                        </Col>
                        <Col className="bg-white p-4">
                            <h2>Common <br/> Repairs</h2>
                            We have over 30 common car repairs and the list is growing
                        </Col>
                        <Col>
                            <img src="assets/img/service-2.jpg" alt="Brakes"/>
                        </Col>
                        <Col className="bg-dark text-white p-4">
                            <h2>Brake <br/> Repair &amp; Service</h2>
                            Brake maintenance is important in helping ensure the safety of you and your passengers
                        </Col>
                        <Col>
                            <img src="assets/img/service-3.jpg" alt="Engine"/>
                        </Col>
                    </Row>
                </Container>
            </Container>
              
            <Container fluid="lg" className="services-list  p-4">
				<header className="text-center">
					<h1>Our Services</h1>
					<p>Below are some of the many auto repair services we offer:</p>
				</header>
                <Row>
                    <Col md className="d-flex justify-content-center">
                        <ServiceList name={services1}/>			
                    </Col>
                    <Col md className="d-flex justify-content-center">
                        <ServiceList name={services2}/>	
                    </Col>
                    <Col md className="d-flex justify-content-center">
                        <ServiceList name={services3}/>	
                    </Col>
                </Row>
			</Container>
        
            <Container fluid className="certified bg-light p-4">
                <Container lg>
                    <header className="text-center">
                        <h1>Why Choose Certified Service?</h1>
                    </header>	
                    <Row className="text-center mt-5">			
                        <Col md className="p-4">
                            <div className="icon-wrapper shadow">
                                <span><Calculator size={80} className="text-white"/></span>
                            </div>
                            
                            <h3 className="pt-3">Estimates</h3>
                            <p>We bring you the most accurate and fair-price service estimates</p>
                            
                        </Col>
                        <Col md className="p-4">
                            <div className="icon-wrapper shadow">
                                <span><AwardFill size={80} className="text-white"/></span>
                            </div>
                            
                            <h3 className="pt-3">Trusted</h3>
                            <p>Trusted Service Centers are certified for high quality</p>
                            
                        </Col>						
                    </Row>
                </Container>
		    </Container>

            <Container fluid className="stats  text-center p-4">
                <Container lg>
                    <header>
                        <h2>Some Statistics About Us</h2>
                        <p>Auto Repair Technical Statistics You Must Know</p>
                    </header>		
                    <Row>
                        <Col sm >
                            
                            <div className="fw-bold fs-1 text-primary border-bottom">63</div>
                            <h5 className="pt-3">Years of experience</h5>
                        </Col>

                        <Col sm>
                            <div className="fw-bold fs-1 text-primary border-bottom">2500</div>
                            <h5 className="pt-3">Satisfied customers</h5>
                        </Col>
                            
                        <Col sm>
                            <div className="fw-bold fs-1 text-primary border-bottom">1900</div>
                            <h5 className="pt-3">Vehicle Repaired</h5>
                        </Col>		
                    </Row>
                </Container>
            </Container>

            <Subscribe/>
        
        </>
    )
}
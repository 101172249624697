import { Container, Col, Row } from "react-bootstrap";

export default function About(){
    return(
        <Container fluid className="about-us bg-light p-4">
			<Container>
                <h3>About Us</h3>
                <Row>
                    <Col lg={8}>
                        <p>
                            Ralph's Automotive Services, Inc. has been providing quality car care in Tivoli, NY since 1958. We are a family owned business, delivering honest and professional automotive repair and auto maintenance services to the people of Tivol, Red Hook and Germantown.
                            The quality ASE (automotive service excellence) certified technicians at Ralph's Automotive Services, Inc. employ today’s latest automotive technology and are equipped to handle all major and minor repairs on foreign and domestic vehicles. Ralph's Automotive Services, Inc. is a certified NAPA AutoCare Center, we are able to provide the benefits of an established national entity while maintaining the personal touch of a family owned business. We understand that your vehicle is a major investment, and that is why we care for each customer’s vehicle as if it were our own.
                        </p>
                        <p>
                            As a NAPA AutoCare Center, we follow a strict Code of Ethics so customers will know up front what to expect. As part of this code, we pledge to:
                        </p>
                            
                        <ul>
                            <li>Perform high quality diagnostic and repair service at a fair price using quality NAPA parts</li>
                            <li>Employ ASE certified technicians</li>
                            <li>Be dedicated to customer satisfaction</li>
                            <li>Exercise reasonable care for the customers property while in our possession</li>
                            <li>Obtain prior authorization and provide a price estimate for work performed</li>
                            <li>Provide a system for fair settlement of customer complaints should they occur</li>
                            <li>Maintain the highest standards of our profession</li>
                            <li>Uphold the integrity of all members of the NAPA AutoCare Program</li>
                        </ul>
                    
                        <p>
                            If you have any questions or comments, please don't hesitate to contact us.
                        </p>
                        
                        Sincerely, <br/>
                        Ralph Torchia, CEO
                    </Col>
                    <Col lg={4}>
                        <aside>
                            <img src="assets/img/shop_building.jpg" alt="Shop" className="img-fluid"/>
                        </aside>
                    </Col>
                </Row>
			</Container>
		</Container>
    )
}
// import logo from './logo.svg';
import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import HeaderBar from './components/HeaderBar'
import Menu from "./components/Menu.js"
import Footer from './components/Footer'

import Home from "./Home.js"
import Services from "./Services.js"
import About from "./About.js"
import Faq from './Faq';

export default function App() {
  return (
    <>
      <HeaderBar/>
      <Router>
        
        <Menu/>

        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route path="/services" component={Services}></Route>
          <Route path="/about" component={About}></Route>
          <Route path="/faq" component={Faq}></Route>
        </Switch>
        <Footer/>
      </Router>
    </>
  );
}

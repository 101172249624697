import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap'
import { ClockFill, GeoAltFill, Facebook } from 'react-bootstrap-icons'
import Map from './Map.js'
import '../css/Footer.scss'

export default function Footer(){

    return(
		<>
			<Container fluid>
				<Row style={{minHeight: 455 + "px"}}>
					<Col className="footer-contact fs-5" md="5">
						<h2 className="h-phone"><span className="color"><a href="tel:8457572431">(845) 757-2431</a></span></h2>
						
						<div className="footer-contact-address">
							<GeoAltFill className="text-primary fs-1"/>
							<div className="d-inline-block ms-4 align-top">5135 Route 9G<br/> Tivoli, NY 12583</div>
						</div>
						<div className="footer-contact-hours">
							<ClockFill className="text-primary fs-1"/>
							<div className="d-inline-block ms-4 align-top">
								Monday-Friday <span className="color">8:00AM - 5:30PM</span><br/>
								Saturday Closed<br/>
								Sunday Closed
							</div>
						</div>
						
						<span className="social-links">
							<a href="https://www.facebook.com/RalphsAutomotiveServices/" target="_blank" rel="noreferrer"><Facebook className="fs-1"/></a>	
						</span>
					</Col>

					<Col className="p-0">
						<Map/>
					</Col>
				</Row>
			</Container>
				
			<Container fluid className="bg-light p-3">
				<Container md="true">
					<Row>
						<Col>
							<dl>
								<dt>Navigation</dt>
								<dd><Link to="/">Home</Link></dd>

								<dd><Link to="/faq">FAQ</Link></dd>
							</dl>
						</Col>
                    
						<Col>
							<dl>
								<dt>Learn More</dt>
								<dd><Link to="/services">Services</Link></dd>
								<dd><Link to="/about">About Us</Link></dd>
							</dl>
						</Col>

						<Col>
							<dl>
								<dt>Policies</dt>
								<dd><Link to="/privacy">Privacy Policy</Link></dd>
							</dl>
						</Col>

						<Col>
							<dl>
								<dt>Support</dt>
								<dd><Link to="/contact">Contact Us</Link></dd>
							</dl>
						</Col>

						<Col>
							<a href="http://www.napaautocare.com/" target="_blank" rel="noreferrer">
								<img src="Napa-Auto-Care-Logo.png" className="img-fluid" alt="NAPA AutoCare Center"/>
							</a>
						</Col>
                    </Row>
						
					<div className="footer-copyright py-3">
						&copy; {new Date().getFullYear()} Ralph's Automotive Services, Inc.	
					</div>
				</Container>
			</Container>
			
			</>
		// </footer>
    )
}
import Container from 'react-bootstrap/Container'
import { GeoAltFill, ClockFill, TelephoneFill } from 'react-bootstrap-icons'
import '../css/HeaderBar.scss'

export default function HeaderBar() {

    return(
        <Container fluid className="headerBar d-none d-md-block fw-bold">
            <span><GeoAltFill size={14} className="mb-1 me-2"/> 5014 Route 9G, Tivoli, NY 12583</span>
            <span><ClockFill size={14} className="mb-1 me-2"/>Monday - Friday: 8:00-5:30</span>
            <span><TelephoneFill size={14} className="mb-1 me-2"/><a href="tel:8457572431" className="link-light">(845) 757-2431</a></span>
        </Container> 
    )
}
import { CheckLg } from 'react-bootstrap-icons'

export default function ServiceList(props) {
    const service = props.name;
    const listItems = service.map((service) =>
        <li key={service} className="pb-2"><CheckLg className="text-primary me-3"/>{service}</li>
    );
    return (
        <ul className="service-list list-unstyled">{listItems}</ul>
    )
}
import { Container } from "react-bootstrap"

export default function Subscribe(){
    return (
        <Container fluid className="subscribe bg-light p-5 text-center">
            <h3>Subscribe</h3>
            <p >Sign up to hear from us about specials, sales and events</p>
            <form>
                <input type="email" className="form-control w-50 align-center d-inline" placeholder="Email Address"/>
                <input id="subscribe-form-submit" type="submit" class="btn btn-primary rounded-pill d-inline ms-3 text-uppercase fw-bold" value="Sign up"/>
            </form>
        </Container>
    )
}
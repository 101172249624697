import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

export default function Map(){
    const mapStyles = {       
        height: "100%",
        width: "100%",
    }; 
    
    const options = {
        zoomControl: 0,
        fullscreenControl: 0,
        streetViewControl: 0,
        mapTypeControl: 0,
        scrollwheel: 0,
        panControl: 0,
        gestureHandling: "none",
        disableDefaultUI: 1,
        styles: [
            {
                "featureType": "water", 
				"elementType": "geometry",
				"stylers": [{"color": "#e9e9e9"}, {"lightness": 17}]
            },
            {
				"featureType": "landscape",
				"elementType": "geometry",
				"stylers": [{"color": "#f5f5f5"}, {"lightness": 20}]
			},
			{
				"featureType": "road.highway",
				"elementType": "geometry.fill",
				"stylers": [{"color": "#ffffff"}, {"lightness": 17}]
			},
			{
				"featureType": "road.highway",
				"elementType": "geometry.stroke",
				"stylers": [{"color": "#ffffff"}, {"lightness": 29}, {"weight": 0.2}]
			},
			{
				"featureType": "road.arterial",
				"elementType": "geometry",
				"stylers": [{"color": "#ffffff"}, {"lightness": 18}]
			},
			{
				"featureType": "road.local",
				"elementType": "geometry",
				"stylers": [{"color": "#ffffff"}, {"lightness": 16}]
			},
			{
				"featureType": "poi",
				"elementType": "geometry",
				"stylers": [{"color": "#f5f5f5"}, {"lightness": 21}]
			},
			{
				"featureType": "poi.park",
				"elementType": "geometry",
				"stylers": [{"color": "#dedede"}, {"lightness": 21}]
			},
			{
				"elementType": "labels.text.stroke",
				"stylers": [{"visibility": "on"}, {"color": "#ffffff"}, {"lightness": 16}]
			},
			{
				"elementType": "labels.text.fill",
				"stylers": [{"saturation": 36}, {"color": "#333333"}, {"lightness": 40}]
			},
			{
				"elementType": "labels.icon",
				"stylers": [{"visibility": "off"}]
			},
			{
				"featureType": "transit",
				"elementType": "geometry",
				"stylers": [{"color": "#f2f2f2"}, {"lightness": 19}]
			},
			{
				"featureType": "administrative",
				"elementType": "geometry.fill",
				"stylers": [{"color": "#fefefe"}, {"lightness": 20}]
			},
			{
				"featureType": "administrative",
				"elementType": "geometry.stroke",
				"stylers": [{"color": "#fefefe"}, {"lightness": 17}, {"weight": 1.2}]
			}
        ]
    }

    const defaultCenter = {
        lat: 42.06050,
        lng: -73.9000
    }

    const svgIcon = {
        path: "M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z",
        fillColor: "gold",
        fillOpacity: 1,
        scale: 3,
        strokeColor: "#ffc107",
        strokeWeight: 2,
    }
  
    return (
    <LoadScript googleMapsApiKey="AIzaSyB1OCPALyEQQoLilpZXkDgjE5_iS_Tq_KY">
        <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={15}
            center={defaultCenter}
            options={options}
        >

            <Marker
                position={defaultCenter}
                icon={svgIcon}
            />
        </GoogleMap>
     </LoadScript>
    )
}

import { Card, Col } from 'react-bootstrap'

export default function ServiceCard(props){
    return(
        <Col>
            <Card className="h-100">
                <Card.Img variant="top" src={'assets/img/services-img-0' + props.id + '.jpg' } alt={props.title}/>
                <Card.Body>
                    <Card.Title className="fs-4">{props.title}</Card.Title>
                    <Card.Body >
                        {props.desc}
                    </Card.Body>
                </Card.Body>
            </Card>
        </Col>
    )
}

import { Container, Row } from 'react-bootstrap'
import ServiceCard from './components/ServiceCard'

export default function Services() {

    const services = [
        {
            id: 1,
            title: "Preventative Maintenance",
            desc: "The best way to minimize breakdowns is doing routine maintenance"
        },
        {
            id: 2,
            title: "Brake Repair & Service",
            desc: "Brakes wear out over time requiring service"
        },
        {
            id: 3,
            title: "Transmission Service & Repair",
            desc: "The transmission is complicated and important components of your car"
        },
        {
            id: 4,
            title: "Tires & Wheels",
            desc: "Tires are important to maintain traction on the road"
        },
        {
            id: 5,
            title: "Engine Services",
            desc: "Keeping your engine running smoothly keeps your car running longer"
        },
        {
            id: 6,
            title: "Exhaust System",
            desc: "An exhaust system helps reduce emissions levels"
        }
    ]

    const listedServices = services.map((service) =>
        <ServiceCard id={service.id.toString()} title={service.title} desc={service.desc} key={service.id}/>
    )

    return(
        <Container fluid className="bg-light text-center p-4">
           <Container className="services-container">
                <header>
                    <h2>What We Do</h2>
                    <p>We offer full service auto repair &amp; maintenance</p>
                </header>

                <Row xs={1} md={2} lg={3} className="g-4">

                    { listedServices }

                </Row>	
		    </Container>
        </Container>
    )
}